/* Calendar container to display months in rows of 3 */
.calendar-container {
    display: grid; /* Change to grid layout */
    grid-template-columns: repeat(3, 1fr); /* Create 3 equal columns */
    gap: 20px;
    padding: 20px;
    justify-items: center; /* Center the items horizontally */
}

/* Styling for each month */
.calendar-month {
    width: 100%; /* Full width inside its grid cell */
    max-width: 350px; /* Optional: control the width of each month */
}

/* Header for month and year */
.calendar-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
}

/* Ensure no underlining on individual weekday names */
.react-calendar__month-view__weekdays__weekday {
    text-decoration: none !important; /* Remove underline */
    color: #333; /* Optional: Set weekday text color */
}

/* Style for available dates (pale green) */
.available {
    background-color: #d4edda !important;  /* Pale green */
    color: black !important;
    border: 1px solid #333; /* Dark grey thin border */
}

/* Style for unavailable dates (pale red) */
.unavailable {
    background-color: rgb(243, 153, 153) !important;  /* Pale red */
    color: black !important;
    border: 1px solid #333; /* Dark grey thin border */
}

/* Additional styles for hover effects */
.react-calendar__tile:enabled:hover {
    opacity: 0.8;
    cursor: pointer;
}

/* Style for today's date (optional) */
.react-calendar__tile--now {
    border: 2px solid #ffa500 !important; /* Orange border for today */
    background-color: #fff9e6 !important;
    color: #333;
}

/* Ensure no underline on weekdays */
.react-calendar__month-view__weekdays {
    text-decoration: none !important; /* Remove underline */
    font-weight: bold; /* Optional: Make weekdays bold */
}

/* Optionally, customize the appearance of the weekday names */
.react-calendar__month-view__weekdays__weekday {
    text-decoration: none !important; /* Ensure no underline for weekday names */
    color: #333; /* Optional: Set custom color for weekdays */
}

/* Ensure no underline on the date tiles */
.react-calendar__tile {
    text-decoration: none !important; /* Remove any underline from date cells */
    display: table-cell;
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #333; /* Dark grey thin outline around cells */
}

/* Optional: Adjusting the appearance of selected date(s) */
.react-calendar__tile--active {
    background-color: #4caf50 !important;
    color: white !important;
}

/* Hide the navigation arrows */
.react-calendar__navigation {
    display: none !important;
}

/* Hide the month label */
.react-calendar__month-view__header__label {
    display: none !important;
}

/* Remove the next and previous buttons */
.react-calendar__navigation button {
    display: none !important;
}

/* Make days from previous/next month appear white */
.react-calendar__tile--neighboringMonth {
    background-color: white !important;
    color: white !important;
    border-color: white !important; /* Hide the borders */
    pointer-events: none; /* Disable clicking on neighboring days */
}

/* Optional: Add some border or shadow for clarity */
.react-calendar__tile--neighboringMonth:hover {
    background-color: transparent !important;
}

/* Fix styling for days that may appear in neighboring month cells */
.react-calendar__month-view__days__day--neighboringMonth {
    background-color: white !important;
    color: white !important;
    border-color: white !important;
    pointer-events: none;
}
